import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CircleLoader } from '../../../loader';

export const ValidationLoader = () => {
  const { t } = useTranslation();

  return (
    <>
      <span className='validation-message'>
        {t('terms.player_validation_message')}
      </span>
      <CircleLoader></CircleLoader>
    </>
  );
};
