
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';

const ErrorPage = () => {
   const { t } = useTranslation();
   const returnToPartner = () => {
        window.location.href = 'https://bet3000.de';
    }
  return (
    <div className='errors'>
      <div>
            <div className='errors-modal-wrapper'>
                <div className='errors-modal'>
                    {t('errorPage.popup.errorModalText')}
                    <div>
                        <button
                        className='terms-submit'
                        type='button'
                        onClick={returnToPartner}
                        >
                        {t('errorPage.popup.closeButton')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ErrorPage;