import React from "react";
import { useTranslation } from "react-i18next";
import { EnglishImprint } from "./languageImprints/EnglishImprint";
import { GermanImprint } from "./languageImprints/GermanImprint";
import { TurkishImprint } from "./languageImprints/TurkishImprint";

const languageImprintComponents = {
  en: EnglishImprint,
  de: GermanImprint,
  tr: TurkishImprint,
};

export const PopupImprint = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  const ImprintComponent = languageImprintComponents[language] || GermanImprint;
  return <ImprintComponent />;
};
