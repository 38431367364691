import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { dictionaries } from "../consts/i18n";

export function configureI18n(locale = "de") {
  const isLocaleAvailable = (lang) => {
    return dictionaries.hasOwnProperty(lang);
  };

  if (!isLocaleAvailable(locale)) {
    locale = "de";
  }

  i18n.use(initReactI18next).init({
    resources: {
      de: { translation: dictionaries.de },
      en: { translation: dictionaries.en },
      tr: { translation: dictionaries.tr },
    },
    lng: locale,
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
  });
}
