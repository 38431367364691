import React, { useEffect, useState, useRef } from 'react';
import { TermsForm } from '../../form/terms/TermsForm';
import { useTranslation } from 'react-i18next';
import { ValidationLoader } from './validation-loader';
import { apiPost } from '../../../services/api';
import './index.css';
import { PopupImprint } from './popup-links/imprint';
import { PopupDataPrivacy } from './popup-links/data-privacy';
import { PopupAgb } from './popup-links/agb';
import { LanguageSelect } from '../../shared/languageSelect';

export const TermsModal = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [popupText, setPopupText] = useState();
  const togglePopup = (text) => {
    setPopupText(text);
    setPopupVisibility(!isPopupVisible);
    window.scrollTo(0,0);
  }
  const closePopup = () => {
    setPopupVisibility(false);
    scrollToTop();
  };

  const openDataPrivacy = () => {
    setPopupText(1);
    setPopupVisibility(true);
    scrollToTop();
  }

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const urlString = window.location.href;
  const url = new URL(urlString);
  const searchParams = url.searchParams;
  const formData = {
    email: searchParams.get('email'),
  };

  const validatePlayer = async () => {
    const response = await apiPost(
      `${process.env.REACT_APP_OCTOPUS_API_URL}/partner-integration/bet3000/sonnenspiele/validate`,
      formData
    );

    if (response && response.valid) {
      setIsLoading(false);
    } else {
      window.location.href = process.env.REACT_APP_SONNENSPIELE_URL;
    }
  };

  const termsFields = [
    t('terms.fields.email'),
    t('terms.fields.birthdate'),
    t('terms.fields.mobile'),
    t('terms.fields.birthplace'),
    t('terms.fields.name'),
    t('terms.fields.birthname'),
    t('terms.fields.address'),
    t('terms.fields.gender'),
  ];

  const popupLink = [
    t('terms.popupLinks.imprint'),
    t('terms.popupLinks.dataProtection'),
    t('terms.popupLinks.agb'),
  ]

  const linkModalRef = useRef(null);
  const scrollToTop = () => {
    linkModalRef.current.scroll({
      top: 0,
    });
  };
  useEffect(() => {
    if (formData.email) {
      validatePlayer();
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <div className='terms'>
      {isLoading ? (
        <div className='validation-loader'>
          <ValidationLoader></ValidationLoader>
        </div>
      ) : (
        <div>
          <div className='terms-modal-wrapper'>
            {isPopupVisible ? (
              <><div className='popup-close-btn'>
               <img className='close-btn' src={require('../../../assets/images/icn_desk-close-black.svg').default} onClick={closePopup} />
                <div className='popup-multilog-logo'></div>
              </div>
              <div className='links-modal' ref={linkModalRef}>
                  {popupText === 0 ? (
                    <PopupImprint />
                  ) : popupText === 1 ? (
                    <PopupDataPrivacy />
                  ) : (
                    <PopupAgb openDataPrivacy={openDataPrivacy} />
                  )}
                  <div className='terms-submit-container'>
                    <button
                      className='terms-submit'
                      type='submit'
                      onClick={closePopup}
                    >
                      {t('terms.popup.closeButton')}
                    </button>
                  </div>
                </div></>
            ) : (
              <><div className='terms-modal'>
                  <div className="terms-modal-header">
                    <div className="multilog-logo" />
                    <LanguageSelect />
                  </div>
                    
                    <h1>{t('terms.heading')}</h1><div className='terms-description'>
                      <p>{t('terms.description')}</p>
                    </div><div className='terms-fields'>
                      {termsFields.map((field, index) => {
                        return <p key={index}>{field}</p>;
                      })}
                    </div><TermsForm togglePopup={togglePopup}></TermsForm>
                  </div><div className='popup-links'>
                      {popupLink.map(({}, key) => {
                        return <p onClick={() => togglePopup(key)} key={key}>{popupLink[key]}</p>;
                      })}
                    </div></>
            )} 
          </div>
        </div>
      )}
    </div>
  );
};
