import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import './index.css';
import { apiPost } from '../../../services/api';
import { eventBus } from '../../../helpers/eventBus/eventBus';
import { CircleLoader } from '../../loader';
import { LockIcon } from '../../../icons/LockIcon';
import { useNavigate } from 'react-router-dom';

export const TermsForm = (props) => {
  const { t, i18n } = useTranslation();
  const urlString = window.location.href;
  const url = new URL(urlString);
  const searchParams = url.searchParams;

  const [formData, setFormData] = useState({
    email: null,
    token: null,
    lang: searchParams.get('lang') ? searchParams.get('lang') : 'de',
  });
  const { control, handleSubmit, setValue, watch } = useForm();
  const mainCheckbox = watch('mainCheckbox', false);
  const childCheckbox1 = watch('childCheckbox1');
  const childCheckbox2 = watch('childCheckbox2', false);
  const queryParamsKeys = ['email', 'token'];
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();


  const returnToPartner = () => {
    window.location.href = 'https://bet3000.de';
}

  const childCheckboxes = [
    {
      name: 'childCheckbox1',
      label: (
        <Trans
          i18nKey='terms.checkbox.child1'
          components={{
            termsButton: <span className='agb-description-link' onClick={() => props.togglePopup(2)}/>,
            dataProtectionButton: <span className='agb-description-link' onClick={() => props.togglePopup(1)}/>,
          }}
        />
      ),
    },
    {
      name: 'childCheckbox2',
      label: t('terms.checkbox.child2'),
    },
  ];

  const areAllChildrenSelected =
    childCheckbox1 && childCheckbox2;

  const onSubmit = async () => {
    setIsLoading(true);
    let payload = {
      optInMarketing: childCheckbox2,
      ...formData,
    };

    const response = await apiPost(
      `${process.env.REACT_APP_OCTOPUS_API_URL}/partner-integration/bet3000/sonnenspiele`,
      payload
    );

    setIsLoading(false);

    if (response && response.redirectUrl) {
      window.location.href = response.redirectUrl;
    }

    if (response && response.message && response.code) {
      const message = `terms.apiErrors.${response.code}.message`;
      const severity = `terms.apiErrors.${response.code}.severity`;

      if (i18n.exists(message) && i18n.exists(severity)) {
        eventBus.dispatch('notificationAdded', {
          message: t(message),
          severity: t(severity),
        });
      }
    }
  };

  const mainCheckboxHandler = () => {
    childCheckboxes.forEach((item) => {
      setValue(item.name, !mainCheckbox);
    });
  };

  useEffect(() => {
    if (!areAllChildrenSelected) {
      setValue('mainCheckbox', false);
    }
  }, [areAllChildrenSelected]);

  useEffect(() => {
    queryParamsKeys.forEach((item) => {
      if (!searchParams.get(item) && process.env.NODE_ENV !== 'development') {
        navigate("/error");
      }
      setFormData((prevState) => {
        return {
          ...prevState,
          [item]: searchParams.get(item),
        };
      });
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='terms-form'>
      <div>
        <Controller
          name='mainCheckbox'
          control={control}
          render={({ field }) => (
            <label className='checkbox'>
              <input
                type='checkbox'
                {...field}
                onClick={mainCheckboxHandler}
                checked={
                  field.value &&
                  childCheckbox1 &&
                  childCheckbox2
                }
              />
              <span className='checkbox-label'>{t('terms.checkbox.main')}</span>
            </label>
          )}
        />
      </div>

      <div className='sub-checkbox-container'>
        {childCheckboxes.map((checkbox, index) => (
          <div key={index}>
            <Controller
              name={checkbox.name}
              control={control}
              render={({ field }) => (
                <div className='checkbox-inner-item'>
                  <label className='checkbox'>
                    <input type='checkbox' {...field} checked={field.value} />
                    <span></span>
                  </label>
                  <p className='checkbox-label' >{checkbox.label}</p>
                </div>
              )}
            />
          </div>
        ))}
      </div>

      <div className={`terms-submit-container ${!isMobile ? 'terms-submit-container-mobile' : ''}`}>
        {isMobile && <button
          type='button'
          className='terms-submit back-button'
          onClick={returnToPartner}
        >
        {t('terms.submit.backButtonText')}
        </button>}
        <button
          className='terms-submit'
          type='submit'
          disabled={!mainCheckbox && !childCheckbox1}
        >
          {isLoading ? (
            <CircleLoader></CircleLoader>
          ) : (
            t('terms.submit.buttonText')
          )}
        </button>
      </div>
      <div className='submit-button-description-wrapper'>
        <span id='submit-button-description-text'>
          <LockIcon></LockIcon>
          {t('terms.submit.buttonDescription')}
        </span>
      </div>
    </form>
  );
};
