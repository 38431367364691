import { useSearchParams } from "react-router-dom";

export function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  function getQueryParam(name) {
    return searchParams.get(name);
  }

  function removeQueryParams(names) {
    setSearchParams(
      (searchParams) => {
        searchParams.delete(names);

        return searchParams;
      },
      { replace: true }
    );
  }

  function setQueryParam(paramName, paramValue) {
    setSearchParams(
      (searchParams) => {
        searchParams.set(paramName, paramValue);

        return searchParams;
      },
      { replace: true }
    );
  }

  return {
    getQueryParam,
    setQueryParam,
    removeQueryParams,
  };
}
