import axios from 'axios';

export async function apiPost(url, payload) {
  try {
    const response = await axios.post(url, payload);

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
  }
}
