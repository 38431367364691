import React from "react";

export const EnglishImprint = () => {
  return (
    <div className="popup-imprint">
      <p>MultiLog24 GmbH</p>
      <p className="highlighted-subtitle">Headquarters of the company</p>
      <p>Merkur-Allee 1-15, 32339 Espelkamp</p>
      <p className="highlighted-subtitle">VAT identification number</p>
      <p>DE365521135</p>
      <p className="highlighted-subtitle">Information on the commercial register</p>
      <p>Amtsgericht Bad Oeynhausen</p>
      <p>HRB 15168</p>
      <p className="highlighted-subtitle">Managing Director</p>
      <p>Thomas Bone, Jochen Clemens</p>
      <p className="highlighted-subtitle">E-Mail-contact</p>
      <p> support@multilog24.de</p>
      <p className="highlighted-subtitle">Phone-Nr.</p>
      <p>05741 &ndash; 273-1212</p>
      <p style={{ fontSize: "12px" }}>
        MultiLog24 GmbH, Merkur-Allee 1-15, 32339 Espelkamp, HRB 15168
      </p>
    </div>
  );
};
