import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { languages } from "../../../consts/languageOptions";
import "./style.css";

export const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const { setQueryParam } = useQueryParams();

  const [isLanguageSwitchOpen, setIsLanguageSwitchOpen] = useState(false);
  const lang = i18n.language;

  const handleChangeLanguage = (lang) => {
    try {
      setQueryParam("lang", lang);
      i18n.changeLanguage(lang);
      setIsLanguageSwitchOpen(false);
    } catch (_) {
      setIsLanguageSwitchOpen(false);
    }
  };

  return (
    <div className="language-list-container">
      {!isLanguageSwitchOpen && (
        <div
          onClick={() => {
            setIsLanguageSwitchOpen(true);
          }}
          className="header-navigation__current-lang"
          role="button"
        >
          <div
            className={`lang__btn-flag lang__btn-flag--${lang} lang__flag lang__flag--${lang}`}
          />
        </div>
      )}

      {isLanguageSwitchOpen && (
        <div className="language-list">
          {languages.map(({ value }) => (
            <div
              key={value}
              role="button"
              className={`lang__flag lang__flag--${value}`}
              onClick={() => {
                handleChangeLanguage(value);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
