import React, { useEffect, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { eventBus } from '../../helpers/eventBus/eventBus';
import { useWindowSize } from '../../hooks/useWindowSize';

export const NotificationService = () => {
  const [notifications, setNotifications] = useState([]);
  const windowSize = useWindowSize();

  useEffect(() => {
    eventBus.on('notificationAdded', (data) => {
      setNotifications((prevState) => {
        return [data, ...prevState];
      });
    });
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      setTimeout(() => {
        setNotifications((prevState) => {
          return prevState.slice(0, -1);
        });
        window.location.href = process.env.REACT_APP_SONNENSPIELE_URL;
      }, 3000);
    }
  }, [notifications]);

  return notifications.length > 0 ? (
    <Snackbar
      open={true}
      anchorOrigin={{
        vertical: windowSize.width > 592 ? 'top' : 'bottom',
        horizontal: windowSize.width > 592 ? 'right' : 'center',
      }}
    >
      <Alert
        severity={notifications[0].severity}
        sx={{ '.MuiAlert-action': { paddingTop: '0' } }}
      >
        {notifications[0].message}
      </Alert>
    </Snackbar>
  ) : null;
};
