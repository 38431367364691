import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PopupAgb = (props) => {
  const { t } = useTranslation();

  return (
    <div className='popup-imprint'>
      <p className='highlighted-title'>
        Nutzungsbedingungen f&uuml;r Nutzer von MultiLog24
      </p>
      <p className='highlighted-subtitle'>1. Geltungsbereich</p>

      <p>
        1.1 Diese Nutzungsbedingungen gelten für die Nutzung der von MultiLog24
        entwickelten Hard- und Software-Lösungen (im Folgenden: MultiLog24), die
        von der MultiLog24 GmbH (im Folgenden: Betreiber) dem Nutzer zur
        Verfügung gestellt werden.
      </p>
      <p>
        1.2 Allgemeine Geschäftsbedingungen des Nutzers finden keine Anwendung,
        selbst wenn der Betreiber deren Geltung nicht widerspricht oder
        vorbehaltlos Leistungen erbringt.
      </p>
      <p>
        1.3 Diese Nutzungsbedingungen gelten nur für die Nutzung von MultiLog24.
        Auf alle weiteren Vertragsbeziehungen mit den angeschlossenen Partnern
        und deren Dienstleistungen finden diese Nutzungsbedingungen keine
        Anwendung. Es gelten insoweit ausschließlich die Vertragsbedingungen der
        jeweiligen Partner. Der Nutzer hat diese bei den jeweiligen
        Online-Angeboten separat einzusehen und ggf. zu akzeptieren.
      </p>

      <p className='highlighted-subtitle'>
        2. Vertragsgegenstand, Vertragssprache und Vertragstext
      </p>
      <p>
        2.1 Der Betreiber bietet über MultiLog24 die Möglichkeit zur Anlage
        eines Nutzerkontos (auch myOneID). Über MultiLog24 können die Nutzer auf
        Basis ihrer getroffenen Auswahl Nutzerkonten mit Registrierung unter
        Eingabe der erforderlichen Nutzerdaten und soweit erforderlich
        Verifikation bei Online-Angeboten Dritter (im Folgenden: Partnerdienste)
        anlegen sowie die Konten pflegen und aktualisieren. Sowohl die
        Verwaltung der Nutzerdaten als auch die Verifizierung der Nutzerdaten
        sind Bestandteil der Services über MultiLog24.
      </p>
      <p>
        2.2 Der Nutzer beauftragt hiermit den Betreiber, über MultiLog24 seine
        Nutzerdaten und Dokumente zu verwalten und den vom ihm ausgewählten
        Partnerdiensten für die Anlage eines Nutzerkontos zu übermitteln. Es
        obliegt den jeweiligen Partnerdiensten den Nutzer als Nutzer des
        Partnerdienstes zu akzeptieren und die vom Nutzer gewünschte
        Erstregistrierung bei dem jeweiligen Partnerdienst durchzuführen.
      </p>
      <p>
        2.3 Einige Partnerdienste setzen zwingend voraus, dass der Nutzer
        volljährig ist oder ein bestimmtes Mindestalter aufweist, sodass
        andernfalls die Registrierung bei dem Partnerdienst nicht möglich ist.
      </p>
      <p>
        2.4 Über sein Nutzerkonto bei MultiLog24 gewährt der Nutzer für die
        Erstregistrierung dem jeweiligen Partnerdienst Zugriff auf seine zur
        Verifikation und Registrierung erforderlichen Daten.
      </p>
      <p>2.5 Vertragssprache ist deutsch.</p>
      <p>
        2.6 Der Betreiber speichert den Vertragstext. Den Vertragstext und die
        ausgewählten Partnerdienste kann der Nutzer in seinem Nutzerkonto
        einsehen.
      </p>

      <p className='highlighted-subtitle'>3. Registrierung</p>
      <p>
        3.1 Für die Nutzung der Funktionen ist eine Registrierung bei MultiLog24
        und die Verifikation der Daten erforderlich.
      </p>
      <p>
        3.2 Die Registrierung erfolgt zwingend unter Angabe des Vor- und
        Nachnamens, einer E-Mail-Adresse und einer Mobilfunknummer. An die
        E-Mail-Adresse wird vom Betreiber eine Bestätigungsmail versandt. Die
        Mobilfunknummer muss mittels eines Verifizierungscodes via SMS-TAN
        verifiziert werden. Um den Registrierungsvorgang beim Betreiber
        abzuschließen und die angegebene E-Mail-Adresse und Mobilfunknummer zu
        verifizieren, muss der Nutzer diese bestätigen.
      </p>
      <p>
        3.3 Anschließend kann der Nutzer die Partnerdienste auswählen, die er
        nutzen möchte. Für die Anlage der Nutzerkonten bei den ausgewählten
        Partnerdiensten ist teils die Verifizierung einzelner Nutzerdaten
        erforderlich. Der Betreiber bedient sich für die Verifizierung der
        Dienstleistungen von externen Dienstleistern. Technische
        Verifizierungsverfahren sind hierfür an der Eingabemaske von MultiLog24
        eingebunden. Diese kann der Nutzer kostenlos nutzen.
      </p>
      <p>
        3.4 Anschließend werden die Daten an die vom Nutzer ausgewählten
        Partnerdienste übermittelt, von denen der Nutzer wiederum eine
        Bestätigung per E-Mail erhält. Ggf. muss der Nutzer zusätzlich
        Vertragsbedingungen des jeweiligen Partnerdienstes akzeptieren, worauf
        er vom Partnerdienst hingewiesen wird.
      </p>
      <p>
        3.5 Mit dem vom Nutzer festgelegten Passwort kann er sich sowohl bei
        MultiLog24 einloggen als auch bei den jeweiligen Partnerdiensten, soweit
        das Passwort von diesen akzeptiert wird.
      </p>
      <p>
        3.6 Einige Partnerdienste bieten den Nutzern ggf. Willkommens-Angebote
        an, die nach erfolgreicher Kontoanlage bei den Partnerdiensten angezeigt
        werden. Der Betreiber hat keinen Einfluss auf solche
        Willkommens-Angebote und der Nutzer hat keinen Anspruch hierauf.
      </p>

      <p className='highlighted-subtitle'>4. Entgeltpflichtige Leistungen</p>
      <p>
        4.1 Der Betreiber bietet den Nutzern den Erwerb von Gutscheinen und
        Prepaid-Produkten über MultiLog24 an. Für entgeltpflichtige Bestellungen
        über MultiLog24 gelten ergänzend weitere Vertragsbedingungen, die bei
        der Bestellung akzeptiert werden müssen.
      </p>

      <p className='highlighted-subtitle'>
        5. Umfang der Nutzungsrechte und Nutzungsbeschr&auml;nkungen
      </p>
      <p>
        5.1 MultiLog24 ist urheberrechtlich geschützt. Alle Rechte an MultiLog24
        stehen im Verhältnis zwischen dem Betreiber und dem Nutzer untereinander
        ausschließlich dem Betreiber zu.
      </p>
      <p>
        5.2 Der Betreiber räumt dem Nutzer mit erfolgreicher Registrierung und
        Akzeptierung dieser Nutzungsbedingungen das einfache,
        nicht-ausschließliche, nicht-übertragbare, auf die Dauer der
        Registrierung beschränkte und jederzeit widerrufliche Recht ein, die
        Funktionen von MultiLog24 zur Unterstützung bei der Kontoanlage bei den
        jeweiligen Partnerdiensten zu nutzen („Lizenz“).
      </p>
      <p>
        5.3 Der Nutzer ist nicht berechtigt, ohne Zustimmung des Betreibers
        MultiLog24 zu vermieten oder in sonstiger Weise unterzulizenzieren,
        öffentlich wiederzugeben, insbesondere öffentlich zugänglich zu machen
        oder Dritten entgeltlich oder unentgeltlich zur Verfügung zu stellen.
      </p>
      <p>
        5.4 Das Recht zur Nutzung von MultiLog24 bezieht sich auf die jeweils im
        Zeitpunkt der konkreten Nutzung aktuelle und verfügbare Version.
      </p>

      <p className='highlighted-subtitle'>
        6. Zugriff auf MultiLog24 und technische Voraussetzungen
      </p>
      <p>
        6.1 Für den Zugriff auf MultiLog24 über MultiLog24 benötigt der Nutzer
        einen die E-Mail-Adresse, Handynummer mit SMS-TAN und ggf. ein Passwort,
        gemeinsam auch als Zugangsdaten bezeichnet. Die Zugangsdaten werden von
        dem Nutzer bei der Registrierung bestimmt.
      </p>
      <p>
        6.2 Der Betreiber kann die Anforderungen für den Zugriff auf MultiLog24
        jederzeit dem jeweils aktuellen Stand der Technik entsprechend anpassen.
        Der Nutzer kann sich jederzeit über die aktuellen Anforderungen beim
        Betreiber informieren.
      </p>

      <p className='highlighted-subtitle'>
        7. Verf&uuml;gbarkeit von MultiLog24
      </p>

      <p>
        7.1 Der Betreiber bemüht sich, die Möglichkeit zur Nutzung von
        MultiLog24 möglichst unterbrechungsfrei sicherzustellen. Der Betreiber
        strebt dabei eine ganztägige Verfügbarkeit auch an Feiertagen von 95 %
        durchgehend 24 Stunden an 7 Tagen in der Woche pro Jahr an. Eine
        Gewährleistung für die störungsfreie Nutzungsmöglichkeit von MultiLog24
        übernimmt der Betreiber nicht.
      </p>
      <p>
        7.2 Um die Nutzung von MultiLog24 aufrechtzuerhalten, Fehler zu
        beseitigen und zur Aktualisierung der hinterlegten Informationen ist es
        erforderlich, dass MultiLog24 von Zeit zu Zeit gewartet wird. Der
        Betreiber ist daher berechtigt, regelmäßige Wartungen vorzunehmen. In
        den Wartungszeiten steht MultiLog24 nicht oder nur eingeschränkt zur
        Verfügung.
      </p>
      <p>
        7.3 Der Betreiber wird sich bemühen, die Wartungszeiten möglichst kurz
        zu halten und alle Wartungsarbeiten möglichst zu nutzungsarmen Zeiten
        durchzuführen, möglichst außerhalb der üblichen Geschäftszeiten.
      </p>
      <p>
        Der Betreiber bietet den Nutzern (Mo-Fr 9-19 Uhr, Sa 9-14 Uhr) sowohl
        einen telefonischen Support unter +49 5741 273-1212 also auch Support
        via Mail unter
      </p>
      <p>support@multilog24.de an.</p>

      <p className='highlighted-subtitle'>8. Pflichten des Nutzers</p>
      <p>
        8.1 Sollten sich bei der Nutzung von MultiLog24 Probleme, Fehler oder
        Unstimmigkeiten ergeben, so wird der Nutzer den Betreiber hierüber
        unverzüglich informieren. Auf Verlangen des Betreibers wird der Nutzer
        seine Mitteilung konkretisieren und den Betreiber bei der Analyse des
        Problems unterstützen.
      </p>
      <p>
        8.2 Der Nutzer ist für die Dauer der Nutzung von MultiLog24 zur
        Einhaltung aller Vorgaben dieser Nutzungsbedingungen sowie aller
        sonstigen Vorgaben des Betreibers und der Partnerdienste verpflichtet,
        wie sie dem Nutzer vom Betreiber vor bzw. bei Beginn des
        Nutzungsverhältnisses durch den Partnerdienst mitgeteilt wurden.
      </p>
      <p>
        8.3 Der Nutzer wird alle Maßnahmen und Handlungen unterlassen, die die
        Stabilität und Sicherheit von MultiLog24 gefährden.
      </p>
      <p>
        8.4 Der Nutzer ist verpflichtet, die Daten vor Eingabe in MultiLog24
        genau auszuwählen und auf Plausibilität, Richtigkeit und Vollständigkeit
        zu prüfen.
      </p>
      <p>
        8.5 Der Nutzer ist verpflichtet, ausreichende technische und
        organisatorische Sicherungsvorkehrungen zu treffen, die es verhindern,
        dass Dritte unter Nutzung seiner Zugangsdaten oder auf andere Weise auf
        MultiLog24 zugreifen können. Insbesondere hat er seine Zugangsdaten
        sicher zu verwahren.
      </p>

      <p className='highlighted-subtitle'>9. Haftung</p>
      <p>
        9.1 Der Betreiber stellt MultiLog24 für die Nutzer kostenfrei zur
        Verfügung und haftet vor diesem Hintergrund ausschließlich für Vorsatz
        und grobe Fahrlässigkeit; jegliche Haftung für einfache Fahrlässigkeit
        ist ausgeschlossen.
      </p>
      <p>
        9.2 Die vorstehenden Haftungsbeschränkungen finden keine Anwendung bei
        Verletzung des Lebens, des Körpers oder der Gesundheit sowie bei
        Ansprüchen aus dem Produkthaftungsgesetz. Unberührt bleibt weiter die
        Haftung von Garantien, die vom Betreiber übernommen wurden. Insoweit
        wird klargestellt, dass der Betreiber keine Garantien übernimmt.
      </p>

      <p className='highlighted-subtitle'>10. Datenschutz</p>
      <p>
        <span>
          10.1 Die Parteien sind verpflichtet, die jeweils für sie geltenden
          gesetzlichen Bestimmungen und Vorgaben zum Datenschutz einzuhalten.
          Einzelheiten über die Erhebung, Verarbeitung und Nutzung
          personenbezogener Daten durch den Betreiber ergeben sich aus der{' '}
        </span>
        <span
          className='agb-data-privacy-link'
          onClick={() => props.openDataPrivacy()}
        >
          Datenschutzerkl&auml;rung.
        </span>
      </p>

      <p className='highlighted-subtitle'>11. Laufzeit und K&uuml;ndigung</p>
      <p>
        11.1 Das Nutzungsverhältnis für MultiLog24 wird auf unbestimmte Zeit
        geschlossen. Die Nutzung von MultiLog24 kann von dem Nutzer jederzeit
        ohne Einhaltung einer Frist beendet werden. Der Betreiber behält sich
        vor, die Bereitstellung von MultiLog24 und das Nutzungsverhältnis
        jederzeit ohne Angabe von Gründen zu beenden.
      </p>
      <p>
        11.2 Darüber hinaus kann das Nutzungsverhältnis außerordentlich fristlos
        bei Vorliegen eines wichtigen Grundes gekündigt werden. Ein wichtiger
        Grund liegt vor, wenn dem kündigenden Teil unter Berücksichtigung aller
        Umstände des Einzelfalls und unter Abwägung der beiderseitigen
        Interessen die Fortsetzung des Vertragsverhältnisses nicht zugemutet
        werden kann.
      </p>
      <p>
        11.3 Der Betreiber ist insbesondere zur außerordentlichen Kündigung
        berechtigt, wenn der Nutzer die Nutzung von MultiLog24 einem Dritten
        überlässt oder die Dienstleistungen für MultiLog24 durch Betreiber nicht
        aufrechterhalten wird.
      </p>

      <p className='highlighted-subtitle'>12. Änderung der Nutzungsbedingung</p>
      <p>
        12.1 Der Betreiber ist berechtigt, diese Nutzungsbedingungen oder Teile
        davon zu ändern, wenn die Änderungen geringfügig, sachlich
        gerechtfertigt oder aus rechtlichen oder regulatorischen Gründen
        notwendig sind. Der Betreiber wird keine Änderungen vornehmen, die die
        Nutzung von MultiLog24 von der Zahlung eines Entgelts abhängig machen.
        Über die Änderungen und deren Gründe wird der Betreiber den Nutzer
        spätestens 30 Tage vor Inkrafttreten der Änderungen in Textform (z.B.
        per E-Mail) informieren.
      </p>
      <p>
        12.2 Der Nutzer hat die Möglichkeit, der Änderung zu widersprechen.
        Widerspricht der Nutzer nicht innerhalb einer Frist von 30 Tagen nach
        Zugang der Mitteilung, gilt die Zustimmung in die geänderten
        Nutzungsbedingungen als erteilt. Die geänderten Nutzungsbedingungen
        haben für den Nutzer dann ab dem Tag des Fristablaufes Geltung.
      </p>
      <p>
        12.3 Der Betreiber wird den Nutzer mit der Mitteilung noch einmal
        gesondert auf die Möglichkeit des Widerrufs sowie die Bedeutung seines
        Schweigens hinweisen.
      </p>

      <p className='highlighted-subtitle'>
        13. Plattform zur Onlinestreitbeilegung; Information zur alternativen
        Streitbeilegung in Verbrauchersachen
      </p>
      <p>
        13.1 Die EU-Kommission stellt im Internet unter folgendem Link eine
        Plattform zur Online-Streitbeilegung bereit:{' '}
        <a
          href='https://ec.europa.eu/consumers/odr/'
          target='_blank'
          style={{ textDecoration: 'underline' }}
        >
          https://ec.europa.eu/consumers/odr/
        </a>
        <br />
        Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung
        von Streitigkeiten aus Online-Kauf- oder Dienstleistungsverträgen, an
        denen ein Verbraucher beteiligt ist.
      </p>
      <p>
        13.2 Der Betreiber ist nicht bereit oder verpflichtet, an einem
        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
        teilzunehmen.
      </p>

      <p className='highlighted-subtitle'>14. Schlussbestimmungen</p>
      <p>
        14.1 Falls der Betreiber dem Nutzer diese Nutzungsbedingungen in
        verschiedenen Sprachen zur Verfügung stellt, ist bei Streitigkeiten und
        Auslegungsschwierigkeiten ausschließlich die deutsche Version
        maßgeblich; alle weiteren Sprachversionen sind lediglich Leseversionen
        zugunsten des Nutzers.
      </p>
      <p>
        14.2 Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss
        des UN-Übereinkommens über den internationalen Warenverkehr (CISG). Wenn
        der Nutzer Verbraucher ist und zum Zeitpunkt des Vertragsschlusses
        seinen gewöhnlichen Aufenthalt in einem anderen Land hat, bleibt die
        Anwendung zwingender Rechtsvorschriften dieses Landes von der in Satz 1
        getroffenen Rechtswahl unberührt.
      </p>
      <p>
        14.3 Ausschließlicher Gerichtsstand ist der Sitz vom Betreiber, sofern
        der Nutzer Kaufmann oder juristische Person des öffentlichen Rechts ist
        oder keinen allgemeinen Gerichtsstand in Deutschland hat.
      </p>
      <p>Stand: V1.2 (August 2024)</p>
    </div>
  );
};
