import { TermsModal } from "../layout/terms/TermsModal";
import { NotificationService } from "../notifications";

function Home() {
    return (
      <>
        <NotificationService></NotificationService>
        <TermsModal></TermsModal>
      </>
    );
  }
  
  export default Home;