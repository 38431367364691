import React from "react";

export const TurkishImprint = () => {
  return (
    <div className="popup-imprint">
      <p>MultiLog24 GmbH</p>
      <p className="highlighted-subtitle">Şirketin genel merkezi</p>
      <p>Merkur-Allee 1-15, 32339 Espelkamp</p>
      <p className="highlighted-subtitle">VAT kimlik numarası</p>
      <p>DE365521135</p>
      <p className="highlighted-subtitle">Ticaret siciline ilişkin bilgiler</p>
      <p>Amtsgericht Bad Oeynhausen</p>
      <p>HRB 15168</p>
      <p className="highlighted-subtitle">Genel müdür</p>
      <p>Thomas Bone, Jochen Clemens</p>
      <p className="highlighted-subtitle">E-posta iletişim</p>
      <p> support@multilog24.de</p>
      <p className="highlighted-subtitle">Telefon numarası</p>
      <p>05741 &ndash; 273-1212</p>
      <p style={{ fontSize: "12px" }}>
        MultiLog24 GmbH, Merkur-Allee 1-15, 32339 Espelkamp, HRB 15168
      </p>
    </div>
  );
};
