import React from "react";

export const GermanImprint = () => {
  return (
    <div className="popup-imprint">
      <p>MultiLog24 GmbH</p>
      <p className="highlighted-subtitle">Sitz der Gesellschaft</p>
      <p>Merkur-Allee 1-15, 32339 Espelkamp</p>
      <p className="highlighted-subtitle">Umsatzsteuer-Identifikationsnummer</p>
      <p>DE365521135</p>
      <p className="highlighted-subtitle">Angaben zum Handelsregister</p>
      <p>Amtsgericht Bad Oeynhausen</p>
      <p>HRB 15168</p>
      <p className="highlighted-subtitle">Gesch&auml;ftsf&uuml;hrer</p>
      <p>Thomas Bone, Jochen Clemens</p>
      <p className="highlighted-subtitle">E-Mail-Kontakt</p>
      <p> support@multilog24.de</p>
      <p className="highlighted-subtitle">Tel.-Nr.</p>
      <p>05741 &ndash; 273-1212</p>
      <p style={{ fontSize: "12px" }}>
        MultiLog24 GmbH, Merkur-Allee 1-15, 32339 Espelkamp, HRB 15168
      </p>
    </div>
  );
};
