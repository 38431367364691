import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PopupDataPrivacy = ({text}) => {
  const { t } = useTranslation();

  return (
    <div className='popup-imprint'>
      <p className='highlighted-title'>Datenschutzerkl&auml;rung</p>
      <p className='highlighted-subtitle'>Einleitung</p>
      <p>Wir, die MultiLog24 GmbH, sind als Betreiber des Services der Verantwortliche f&uuml;r die Verarbeitung der personenbezogenen Daten der Nutzer des Services MultiLog24 (im Folgenden: „MultiLog24“). Unsere Kontaktdaten finden Sie im Impressum des Services, die Ansprechpartner f&uuml;r Fragen zur Verarbeitung der personenbezogenen Daten sind direkt in dieser Datenschutzerkl&auml;rung genannt.</p>
      <p>Wir nehmen den Schutz Ihrer Privatsph&auml;re und Ihrer privaten Daten sehr ernst. Wir erfassen, speichern und nutzen Ihre personenbezogenen Daten nur in &Uuml;bereinstimmung mit dem Inhalt dieser Datenschutzerkl&auml;rung sowie den anwendbaren datenschutzrechtlichen Bestimmungen, insbesondere der europ&auml;ischen Datenschutz-Grundverordnung (DSGVO) und den nationalen Datenschutzbestimmungen.</p>
      <p>Mit dieser Datenschutzerkl&auml;rung informieren wir Sie, in welchem Umfang und zu welchen Zwecken personenbezogene Daten im Zusammenhang mit der Nutzung des Services verarbeitet werden.</p>
      <p className='highlighted'>Personenbezogene Daten</p>
      <p>Personenbezogene Daten sind Informationen &uuml;ber eine identifizierte oder identifizierbare nat&uuml;rliche Person. Hierunter fallen alle Informationen zu Ihrer Identit&auml;t wie beispielsweise Ihr Name, Ihre E-Mail-Adresse oder Ihre Postanschrift. Informationen, die nicht mit Ihrer Identit&auml;t in Verbindung gebracht werden k&ouml;nnen (wie zum Beispiel statistische Angaben etwa zur Anzahl der Nutzer des Services), gelten dagegen nicht als personenbezogene Daten.</p>
      <p>Die von uns erhobenen Daten werden grunds&auml;tzlich nur zu Zwecken der Nutzung des Services, insbesondere zur Bereitstellung der gew&uuml;nschten Informationen verarbeitet. Bei der Erhebung von personenbezogenen Daten m&uuml;ssen nur die Daten verpflichtend angegeben werden, die zwingend erforderlich sind. Dar&uuml;ber hinaus k&ouml;nnen weitere Angaben m&ouml;glich sein, <br></br> wobei es sich dann um freiwillige Angaben handelt. Wir weisen jeweils darauf hin, ob es sich um Pflichtfelder oder freiwillige Angaben handelt. &Uuml;ber die konkreten Einzelheiten informieren wir dann in dem entsprechenden Abschnitt dieser Datenschutzerkl&auml;rung.</p>
      <p>Eine automatisierte Entscheidungsfindung auf Basis Ihrer personenbezogenen Daten findet im Zusammenhang mit der Nutzung unseres Services nicht statt.</p>
      <p className='highlighted'>Verarbeitung personenbezogener Daten</p>
      <p>Ihre Angaben werden von uns auf besonders gesch&uuml;tzten Servern innerhalb der Europ&auml;ischen Union gespeichert. Diese sind durch technische und organisatorische Ma&szlig;nahmen gegen Verlust, Zerst&ouml;rung, Zugriff, Ver&auml;nderung oder Verbreitung Ihrer Daten durch unbefugte Personen gesch&uuml;tzt. Der Zugriff auf Ihre Daten ist nur wenigen, befugten Personen m&ouml;glich. Diese sind f&uuml;r die technische, kaufm&auml;nnische oder redaktionelle Betreuung der Server zust&auml;ndig. Trotz regelm&auml;&szlig;iger Kontrollen ist ein vollst&auml;ndiger Schutz gegen alle Gefahren jedoch nicht m&ouml;glich.</p>
      <p>Ihre personenbezogenen Daten werden verschl&uuml;sselt &uuml;ber das Internet &uuml;bertragen. Wir verwenden f&uuml;r die Daten&uuml;bertragung eine SSL-Verschl&uuml;sselung (Secure Socket Layer).</p>
      <p className='highlighted'>Weitergabe personenbezogener Daten an Dritte</p>
      <p>Wir verwenden Ihre personenbezogenen Daten grunds&auml;tzlich nur zur Erbringung der von Ihnen gew&uuml;nschten Leistung. Die Authentifizierung &uuml;ber MultiLog24 dient dabei der Vereinheitlichung von Anmeldevorg&auml;ngen bei den verbundenen Partnerdiensten. Soweit im Rahmen der Leistungserbringung von uns externe Dienstleister eingesetzt werden, erfolgt deren Zugriff auf die Daten auch ausschlie&szlig;lich zum Zwecke der Leistungserbringung, insbesondere im Rahmen der Verifikation. Durch technische und organisatorische Ma&szlig;nahmen stellen wir die Einhaltung der datenschutzrechtlichen Vorgaben sicher und verpflichten auch unsere externen Dienstleister hierauf.</p>
      <p>Wir geben dar&uuml;ber hinaus die Daten nicht ohne Ihre ausdr&uuml;ckliche Einwilligung an Dritte weiter, insbesondere nicht zu Werbezwecken. Eine Weitergabe Ihrer personenbezogenen Daten erfolgt nur, wenn Sie selbst in die Datenweitergabe eingewilligt haben oder soweit wir aufgrund gesetzlicher Bestimmungen und/oder beh&ouml;rdlicher bzw. gerichtlicher Anordnungen hierzu berechtigt oder verpflichtet sind. Dabei kann es sich insbesondere um die Auskunftserteilung f&uuml;r Zwecke der Strafverfolgung, zur Gefahrenabwehr oder zur Durchsetzung von Rechten des geistigen Eigentums handeln.</p>
      <p>Soweit wir Ihre personenbezogenen Daten selbst oder durch Dienstleister in Staaten au&szlig;erhalb der Europ&auml;ischen Union &uuml;bermitteln, halten wir die besonderen Vorgaben der Art. 44 ff. DSGVO hierf&uuml;r ein und verpflichten auch unsere Dienstleister auf die Einhaltung dieser Regelungen. Wir werden Ihre Daten daher nur vorbehaltlich des durch die DSGVO gew&auml;hrleisteten Schutzniveaus in Staaten au&szlig;erhalb der Europ&auml;ischen Union &uuml;bermitteln. Dieses Schutzniveau wird insbesondere durch einen Angemessenheitsbeschluss der EU-Kommission oder durch geeignete Garantien gem&auml;&szlig; Art. 46 DSGVO gew&auml;hrleistet.</p>
      <p className='highlighted'>Rechtsgrundlagen der Datenverarbeitung</p>
      <p>Soweit wir f&uuml;r die Verarbeitung Ihrer personenbezogenen Daten eine Einwilligung einholen, ist Art. 6 Abs. 1 lit. a) DSGVO die Rechtsgrundlage f&uuml;r die Datenverarbeitung.</p>
      <p>Soweit wir Ihre personenbezogenen Daten verarbeiten, weil dies zur Erf&uuml;llung eines Vertrages oder im Rahmen eines vertrags&auml;hnlichen Verh&auml;ltnisses mit Ihnen erforderlich ist, stellt Art. 6 Abs. 1 lit. b) DSGVO die Rechtsgrundlage f&uuml;r die Datenverarbeitung dar.</p>
      <p>Soweit wir Ihre personenbezogenen Daten zur Erf&uuml;llung einer rechtlichen Verpflichtung verarbeiten, ist Art. 6 Abs. 1 lit. c) DSGVO die Rechtsgrundlage f&uuml;r die Datenverarbeitung.</p>
      <p>Als Rechtsgrundlage f&uuml;r die Datenverarbeitung kommt weiter Art. 6 Abs. 1 lit. f) DSGVO in Betracht, wenn die Verarbeitung Ihrer personenbezogenen Daten zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist und dabei Ihre Interessen, Grundrechte und Grundfreiheiten nicht den Schutz der personenbezogenen Daten erfordern.</p>
      <p>Im Rahmen dieser Datenschutzerkl&auml;rung weisen wir immer darauf hin, auf welche Rechtsgrundlage wir die Verarbeitung Ihrer personenbezogenen Daten st&uuml;tzen.</p>
      <p className='highlighted'>Datenl&ouml;schung und Speicherdauer</p>
      <p>Wir l&ouml;schen bzw. sperren Ihre personenbezogenen Daten grunds&auml;tzlich immer dann, wenn der Zweck der Speicherung entf&auml;llt. Eine Speicherung kann aber dar&uuml;ber hinaus erfolgen, wenn dies durch rechtliche Vorgaben, denen wir unterliegen, vorgesehen ist, zum Beispiel im Hinblick auf gesetzliche</p>
      <p>Aufbewahrungs- und Dokumentationspflichten. In einem solchen Fall l&ouml;schen bzw. sperren wir Ihre personenbezogenen Daten nach dem Ende der entsprechenden Vorgaben.</p>
      <p className='highlighted-subtitle'>Nutzerkonto</p>
      <p>Sie k&ouml;nnen auf freiwilliger Basis ein Nutzerkonto f&uuml;r MultiLog24 anlegen. <br></br> In dem Nutzerkonto werden alle Informationen zu Ihrer Person und zur Nutzung der verschiedenen Partnerdienste zentral verwaltet. Rechtsgrundlage f&uuml;r die Verarbeitung der Daten zur Registrierung ist bei einer Einwilligung Art. 6 Abs. 1 lit. a) DSGVO. Soweit Sie sich zur Erf&uuml;llung oder Anbahnung eines Vertrags bei uns registrieren, ist die Rechtsgrundlage f&uuml;r die Verarbeitung der Daten zus&auml;tzlich Art. 6 Abs. 1 lit. b) DSGVO.</p>
      <p className='highlighted'>Registrierung f&uuml;r das Nutzerkonto</p>
      <p>Sie k&ouml;nnen sich auf unseren Eingabe-Oberfl&auml;chen f&uuml;r das Nutzerkonto registrieren. Zur Erstellung des Nutzerkontos ist zun&auml;chst die Angabe einer <br></br> E-Mail-Adresse und Absicherung &uuml;ber ein Kennwort notwendig. <br></br> Au&szlig;erdem ben&ouml;tigen wir die Angabe Ihrer Mobilfunknummer zur Durchf&uuml;hrung der Zwei-Faktor-Authentifizierung. Weitere Angaben k&ouml;nnen auf freiwilliger Basis erg&auml;nzt werden. Wir behalten uns vor, zus&auml;tzlich zur Registrierung &uuml;ber das Nutzerkonto auch weitere M&ouml;glichkeiten zur Registrierung anzubieten, f&uuml;r die dann die Regelungen entsprechend gelten.</p>
      <p>F&uuml;r die Nutzung des vollen Funktionsumfangs des Nutzerkontos kann es erforderlich sein, die bei der Registrierung angegebene Mobilfunknummer und E-Mail-Adresse zu verifizieren. Hierzu &uuml;bersenden wir dann an die angegebene Mobilfunknummer bzw. E-Mail-Adresse einen Best&auml;tigungslink, der von Ihnen aufgerufen werden muss. Dieses Vorgehen dient zum einen der Sicherheit Ihrer Daten, zum anderen auch dazu, die Kommunikation bez&uuml;glich der Services an eine g&uuml;ltige Mobilfunknummer und E-Mail-Adresse sicherzustellen.</p>
      <p className='highlighted'>Datenverarbeitung bei Auswahl eines Partnerdienstes</p>
      <p>W&auml;hrend der Registrierung oder zu einem sp&auml;teren Zeitpunkt &uuml;ber das Nutzerkonto k&ouml;nnen Sie sich bei einem Partnerdienst registrieren. In dem Fall &uuml;bermitteln wir die bei uns hinterlegten Daten an den von Ihnen ausgew&auml;hlten Partnerdienst, soweit dies zur Registrierung bei dem jeweiligen Partnerdienst erforderlich ist.</p>
      <p>Partnerdienste k&ouml;nnen &uuml;ber MultiLog24 die von Ihnen hinterlegten Informationen auch zur Verifizierung und Identifizierung abrufen, die basierend auf Ihrer Auswahl zur Verf&uuml;gung gestellt werden, soweit dies etwa zur Erf&uuml;llung gesetzlicher oder beh&ouml;rdlicher Vorgaben erforderlich ist. Dies k&ouml;nnen folgende Daten sein: Vorname, Nachname, Geburtsname, Adresse, Geburtsdatum, Geburtsort und die Staatsangeh&ouml;rigkeit.</p>
      <p>Wenn Sie den Zugriff des Partnerdienstes auf die &uuml;ber MultiLog24 abrufbaren Informationen widerrufen, k&ouml;nnen Sie nicht mehr darauf zugreifen. Unter Umst&auml;nden m&uuml;ssen Sie den Partnerdienst aber zus&auml;tzlich noch auffordern, dort gespeicherte Daten zu l&ouml;schen. F&uuml;r die Datenverarbeitung bei Nutzung des jeweiligen Partnerdienstes ist ausschlie&szlig;lich der Betreiber des Partnerdienstes verantwortlich. Wir haben darauf keinen Einfluss. Bitte informieren Sie sich daher &uuml;ber die Datenschutzhinweise des Partnerdienstes.</p>
      <p className='highlighted'>Verifizierung</p>
      <p>F&uuml;r einige der angeschlossenen Partnerdienste ist aus Gr&uuml;nden der Suchtpr&auml;vention bei Gl&uuml;cksspielen und des Jugendschutzes eine Verifikation der Person und des Alters erforderlich.</p>
      <p>Es besteht die M&ouml;glichkeit den notwendigen Verifizierungsprozess manuell oder mittels IDaaS vorzunehmen. Die Verifizierung mittels IDaaS muss dann einmalig durchgef&uuml;hrt werden. Daf&uuml;r wird ein Ausweisdokument (Personalausweis / F&uuml;hrerschein / Aufenthaltstitel / Reisepass / Visum) ben&ouml;tigt. <br></br> Das Ausweisdokument wird gescannt und &uuml;berpr&uuml;ft. Zum Abgleich ist der Scan Ihres Gesichts notwendig.</p>
      <p>Zur &Uuml;berpr&uuml;fung der Identit&auml;t im Rahmen der Verifikation nutzen wir den Dienst &bdquo;SonioID&ldquo; des Anbieters Advanced Living Technologies GmbH, Gewerbepark 11, 7412 Wolfau, &Ouml;sterreich (im Folgenden: &bdquo;Sonio&ldquo;). Nach Ihrer ausdr&uuml;cklichen Einwilligung in die Verarbeitung Ihrer biometrischen Daten zu Zwecken der Verifikation wird Ihre Identit&auml;t durch Sonio unter Einhaltung der jeweils g&uuml;ltigen europ&auml;ischen Datenschutzgesetze per Videoverifikation anhand Ihres Ausweisdokuments verifiziert. Mehr &uuml;ber die Datenverarbeitung durch Sonio entnehmen Sie bitte der Datenschutzerkl&auml;rung von Sonio: https://www.sonioid.com/privacy-policy/</p>
      <p className='highlighted'>Erfassung von Daten &uuml;ber das Nutzerkonto</p>
      <p>Im Nutzerkonto werden alle Daten zur Nutzung der Services gespeichert, soweit Sie sich jeweils mit Ihrem Nutzerkonto anmelden. Hierzu geh&ouml;ren insbesondere die pers&ouml;nlichen Daten (Name, Alter, Adressen und Zahlungsinformationen), Gutscheine, verbundene Partnerdienste, Kommunikationshistorie und Zustimmungen zu einzelnen Angeboten (z. B. Newsletter) und von Ihnen explizit oder implizit bereitgestellte Informationen &uuml;ber Interessensschwerpunkte. <br></br> Wir k&ouml;nnen aus diesen Daten Segmente bilden und Nutzer diesen Segmenten zuordnen. Die Zugeh&ouml;rigkeit zu solchen Segmenten wird ebenfalls im Nutzerkonto gespeichert. Ortsbezogene Daten wie Ihre Adresse oder Ihr Standort werden &ndash; soweit Sie Ihre Zustimmung hierf&uuml;r gegeben haben &ndash; gespeichert.</p>
      <p>Ihr aktueller Standort wird gegebenenfalls verwendet, um Ihnen ortsbezogene Angebote unterbreiten zu k&ouml;nnen. Wenn Sie die Speicherung der Daten im Nutzerkonto insgesamt beenden wollen, k&ouml;nnen Sie Ihr Nutzerkonto l&ouml;schen lassen.</p>
      <p className='highlighted'>Nutzung der Daten aus dem Nutzerkonto</p>
      <p>Wir verwenden die gespeicherten Daten zur Abwicklung der gemeinsamen Gesch&auml;ftsbeziehung und &ndash; bei Vorliegen einer entsprechenden Einwilligung &ndash; dazu, Ihnen interessante und relevante Angebote &uuml;ber alle vom Nutzer genutzten Kommunikationswege zu unterbreiten. Anhand der gespeicherten Daten versuchen wir zu ermitteln, welche Angebote f&uuml;r Sie von Relevanz sind.</p>
      <p>Eine Kontaktaufnahme durch uns erfolgt im Rahmen der Gesch&auml;ftsbeziehung &uuml;ber die angegebenen Kontaktdaten, wenn Sie einzelne Leistungen in Anspruch nehmen. Sie erhalten beispielsweise automatisch Bestellbenachrichtigungen oder Hinweise zum Lieferstatus; wir informieren Sie entsprechend Ihrer gew&auml;hlten Pr&auml;ferenzen. Die Kontaktaufnahme kann dabei per E-Mail, durch Nachrichten auf dem Smartphone oder &uuml;ber andere digitale Kommunikationskan&auml;le erfolgen. Zus&auml;tzlich k&ouml;nnen Sie optionale Kommunikationswege und Anl&auml;sse ausw&auml;hlen; hierzu geh&ouml;ren insbesondere spezielle Newsletter und App-Nachrichten.</p>
      <p className='highlighted'>Speicherdauer und L&ouml;schung</p>
      <p>Die bei Nutzung des Nutzerkontos anfallenden Daten werden grunds&auml;tzlich f&uuml;r die Dauer des Bestehens des Nutzerkontos gespeichert, k&ouml;nnen aber auf Antrag auch vorzeitig gel&ouml;scht werden. Ein Gro&szlig;teil der Daten kann direkt online eingesehen und &ndash; bis auf die E-Mail-Adresse &ndash; ge&auml;ndert oder gel&ouml;scht werden. Sie k&ouml;nnen Ihr Nutzerkonto jederzeit l&ouml;schen, indem Sie uns Ihren Wunsch auf L&ouml;schung mitteilen, etwa auch &uuml;ber das allgemeine Kontaktformular. Bei einer sofortigen L&ouml;schung k&ouml;nnen die Daten sp&auml;ter im Falle einer erneuten Registrierung gegebenenfalls nicht wiederhergestellt werden.</p>
      <p className='highlighted-subtitle'>Kommunikation mit uns</p>
      <p>Sie k&ouml;nnen mit uns auf verschiedene Weise in Kontakt treten. Au&szlig;erdem informieren wir Sie gerne regelm&auml;&szlig;ig mit unserem Newsletter per E-Mail.</p>
      <p className='highlighted'>Newsletter</p>
      <p>Bei der Anmeldung zu unserem Newsletter wird Ihre E-Mail-Adresse f&uuml;r eigene Werbezwecke genutzt, bis Sie sich abmelden. Sie erhalten dazu regelm&auml;&szlig;ige Informationen per E-Mail zu aktuellen Themen von uns und der Partnerdienste. Die E-Mails k&ouml;nnen dabei basierend auf unseren Informationen &uuml;ber Sie personalisiert und individualisiert sein.</p>
      <p>F&uuml;r die Anmeldung zu unserem Newsletter verwenden wir das sogenannte Double-Opt-In-Verfahren, d.h. wir werden Ihnen erst dann einen Newsletter per E-Mail zusenden, wenn Sie uns zuvor ausdr&uuml;cklich best&auml;tigt haben, dass wir den Newsletter-Versand aktivieren sollen. Wir werden Ihnen dann eine Benachrichtigungs-E-Mail zusenden und Sie bitten, durch das Anklicken eines in dieser E-Mail enthaltenen Links zu best&auml;tigen, dass Sie unseren Newsletter erhalten m&ouml;chten.</p>
      <p>Die Rechtsgrundlage f&uuml;r die Verarbeitung Ihrer Daten ist Ihre Einwilligung nach Art. 6 Abs. 1 lit. a) DSGVO, wenn Sie sich ausdr&uuml;cklich f&uuml;r den Newsletter angemeldet haben.</p>
      <p>Im Rahmen der gesetzlichen Vorgaben kann es auch m&ouml;glich sein, dass Sie von uns ohne ausdr&uuml;ckliche Einwilligung unseren Newsletter erhalten, weil Sie bei uns Waren oder Dienstleistungen bestellt haben, wir in diesem Zusammenhang Ihre E-Mail-Adresse erhalten haben und Sie dem Erhalt von Informationen per E-Mail nicht widersprochen haben. In diesem Fall ist als Rechtsgrundlage unser berechtigtes Interesse zur &Uuml;bermittlung von Direktwerbung nach Art. 6 Abs. 1 lit. f) DSGVO anzusehen.</p>
      <p>Sollten Sie insgesamt keine Newsletter mehr von uns erhalten wollen, k&ouml;nnen Sie Ihre einmal erteilte Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen bzw. dem weiteren Erhalt des Newsletters widersprechen, ohne dass hierf&uuml;r andere als die &Uuml;bermittlungskosten nach den Basistarifen entstehen. Nutzen Sie einfach den in jedem Newsletter enthaltenen Abmelde-Link.</p>
      <p className='highlighted-subtitle'>Ihre Rechte und Kontakt</p>
      <p>Wir legen gro&szlig;en Wert darauf, die Verarbeitung Ihrer personenbezogenen Daten m&ouml;glichst transparent zu erl&auml;utern und Sie auch &uuml;ber die Ihnen zustehenden Rechte zu informieren. Wenn Sie n&auml;here Informationen w&uuml;nschen oder die Ihnen zustehenden Rechte aus&uuml;ben wollen, k&ouml;nnen Sie sich jederzeit bei uns melden, damit wir uns um Ihr Anliegen k&uuml;mmern.</p>
      <p className='highlighted'>Betroffenenrechte</p>
      <p>Bez&uuml;glich der Verarbeitung Ihrer personenbezogenen Daten stehen Ihnen umfangreiche Rechte zu. Zun&auml;chst haben Sie ein umfangreiches Auskunftsrecht und k&ouml;nnen gegebenenfalls die Berichtigung und/oder L&ouml;schung bzw. Sperrung Ihrer personenbezogenen Daten verlangen. <br></br>Sie k&ouml;nnen auch eine Einschr&auml;nkung der Verarbeitung verlangen und haben ein Widerspruchsrecht. Im Hinblick auf die uns von Ihnen &uuml;bermittelten personenbezogenen Daten steht Ihnen au&szlig;erdem ein Recht auf Daten&uuml;bertragbarkeit zu.</p>
      <p>Wenn Sie eines Ihrer Rechte geltend machen und/oder n&auml;here Informationen hier&uuml;ber erhalten m&ouml;chten, wenden Sie sich bitte an unseren Nutzerservice.</p>
      <p className='highlighted'>Widerruf der Einwilligung und Widerspruch</p>
      <p>Eine einmal von Ihnen erteilte Einwilligung kann jederzeit mit Wirkung f&uuml;r die Zukunft frei widerrufen werden. Durch den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt.</p>
      <p>Sofern die Verarbeitung Ihrer personenbezogenen Daten nicht auf einer Einwilligung beruht, sondern aufgrund einer anderen Rechtsgrundlage erfolgt, k&ouml;nnen Sie dieser Datenverarbeitung widersprechen. Ihr Widerspruch f&uuml;hrt zu einer &Uuml;berpr&uuml;fung und gegebenenfalls Beendigung der Datenverarbeitung. <br></br> Sie werden &uuml;ber das Ergebnis der &Uuml;berpr&uuml;fung informiert und erhalten &ndash; soweit die Datenverarbeitung dennoch fortgesetzt werden soll &ndash; von uns n&auml;here Informationen, warum die Datenverarbeitung zul&auml;ssig ist.</p>
      <p className='highlighted'>Beschwerden</p>
      <p>Wenn Sie der Auffassung sind, dass die Verarbeitung Ihrer personenbezogenen Daten durch uns nicht im Einklang mit dieser Datenschutzerkl&auml;rung oder den anwendbaren Datenschutzbestimmungen erfolgt, steht Ihnen ein Beschwerderecht bei der Aufsichtsbeh&ouml;rde zu.</p>
      <p className='highlighted'>Datenschutzbeauftragter und Kontakt</p>
      <p>F&uuml;r Fragen bezogen auf unseren Umgang mit personenbezogenen Daten oder weitere Informationen zu datenschutzrechtlichen Themen k&ouml;nnen Sie sich gerne direkt an uns bzw. unseren Datenschutzbeauftragten wenden: datenschutz@multilog24.de</p>
      <p className='highlighted-subtitle'>Weitere Informationen und &Auml;nderungen</p>
      <p className='highlighted'>&Auml;nderungen dieser Datenschutzerkl&auml;rung</p>
      <p>Der Stand dieser Datenschutzerkl&auml;rung wird durch die Datumsangabe (unten) kenntlich gemacht. Wir behalten uns das Recht vor, diese Datenschutzerkl&auml;rung jederzeit mit Wirkung f&uuml;r die Zukunft zu &auml;ndern. Eine &Auml;nderung erfolgt insbesondere bei technischen Anpassungen des Services oder bei &Auml;nderungen der datenschutzrechtlichen Vorgaben. Die jeweils aktuelle Version der Datenschutzerkl&auml;rung ist immer direkt &uuml;ber das Service abrufbar. Wir empfehlen Ihnen, sich regelm&auml;&szlig;ig &uuml;ber &Auml;nderungen dieser Datenschutzerkl&auml;rung zu informieren.</p>
      <p>Stand dieser Datenschutzerkl&auml;rung: Dezember 2023</p>
    </div>
  );
};
