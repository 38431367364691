export const LockIcon = () => {
  return (
    <svg
      version='1.1'
      id='Ebene_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 9 12'
      style={{
        enableBackground: 'new 0 0 9 12',
        height: '12px',
        width: '12px',
      }}
      xmlSpace='preserve'
    >
      <path
        className='st0'
        d='M8,12c-2.4,0-4.7,0-7.1,0c0,0-0.1,0-0.1,0C0.4,11.8,0,11.4,0,10.9c0-1.8,0-3.5,0-5.3c0-0.5,0.4-1,0.9-1.1
	c0.2,0,0.4,0,0.6,0c0,0,0-0.1,0-0.1c0-0.5,0-0.9,0-1.4c0-1.3,0.8-2.4,2-2.8C3.8,0.1,4,0.1,4.2,0c0.2,0,0.3,0,0.5,0
	c0.1,0,0.1,0,0.2,0c1.5,0.3,2.6,1.5,2.6,3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.4,0C8.5,4.5,9,5,9,5.6
	c0,1.8,0,3.5,0,5.3c0,0.5-0.3,0.9-0.7,1C8.2,12,8.1,12,8,12z M4.5,11.3c1.1,0,2.2,0,3.3,0c0.3,0,0.4-0.1,0.4-0.4c0-1.7,0-3.4,0-5.1
	c0-0.3-0.1-0.4-0.4-0.4c-2.2,0-4.4,0-6.6,0c-0.3,0-0.4,0.1-0.4,0.4c0,1.7,0,3.4,0,5.1c0,0.3,0.1,0.4,0.4,0.4
	C2.3,11.3,3.4,11.3,4.5,11.3z M2.3,4.5c1.5,0,3,0,4.5,0c0-0.5,0-1.1,0-1.6c-0.1-1.1-1-2.1-2.1-2.1C3.3,0.7,2.3,1.7,2.3,3
	c0,0.5,0,0.9,0,1.4C2.3,4.4,2.3,4.5,2.3,4.5z'
      />
    </svg>
  );
};
